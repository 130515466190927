import { global } from ":global";
import { ENDPOINTS } from "./endpoints";

export async function get_submission_details() {
  return global.api
    .getAuth(ENDPOINTS.get_submission_details)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
}
export async function get_submission_status() {
  return global.api
    .getAuth(ENDPOINTS.get_submission_status)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
}
